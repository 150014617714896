<template>
    <CSubheader class="px-3">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>

</template>

<script>


export default {
  name: 'TheHeader',
  components: {

  }
}
</script>
<style>
.c-subheader .c-header-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250, 183, 0, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
</style>
